import { Avatar } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import "./ChatScreen.css";

function ChatScreen(props) {

    const [input, setInput] = useState('');


    const handleSend = e => {
        e.preventDefault();
        setMessages([...messages, {message: input}]);
        setInput("");
    }

    const [messages, setMessages] = useState([
        {
            name: 'Ellen',
            image: '...',
            message: 'What\'s up'
        }, 
        {
            name: 'Kostas',
            image: '...',
            message: 'Hi!'
        }, 
        {
            message: 'How is it going?'
        }
    ]);

    return (
        <div className="chatScreen">
            <p className="chatScreen__timestamp">YOU MATCHED WITH ELLEN ON 18-02-2021</p>
            {messages.map((message) => (

                message.name ? (
                    <div className="chatScreen__message">
                        <Avatar>
                            className="chatScreen__image"
                            alt={message.name}
                            src={message.image}
                        </Avatar>
                        <p className="chatScreen__text">{message.message}</p>
                    </div>
                ) : (
                    <div className="chatScreen__message">
                        <p className="chatScreen__textUser">{message.message}</p>
                    </div>
                )

                
            ))}
            
            <div className="chatScreen__input">
                <form>
                    <input value={input} className="chatScreen__inputField" placeholder="Type a message..." type="text" onChange={(e) => setInput(e.target.value)}></input>
                    <button onClick={handleSend} type="submit" className="chatScreen__inputButton">SEND</button>
                </form>
            </div>

        </div>
    );
}

export default ChatScreen;