import React, { useState, useEffect, useContext } from "react";
import TinderCard from "react-tinder-card";
import StocksContext from "./StocksContext";
import ResultsContext from "./ResultsContext";
import "./Tindercards.css";
import { Fireworks } from 'fireworks/lib/react'
import ReactDOM from 'react-dom';
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import reactDom from "react-dom";
import "./SwipeButtons.css";

function TinderCards(props) {

    
  const { count, setCount } = useContext(ResultsContext);
  const { stocks, setStocks } = useContext(StocksContext);
  const [ result, setResult ] = useState("");

  let fxProps = {
    count: 3,
    interval: 2000,
    colors: ['#cc3333', '#4CAF50', '#81C784'],
    calc: (props, i) => ({
      ...props,
      x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
      y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
    })
  } 

  const investorTypes = [
    "You are the Active Investor. You spend half your day looking at Reddit for the next Gamestop but the best you have ever achieved is 2% on some $TLSA calls. You probably watch Powell's updates in the bathroom.",
    "You are the Delusional Investor. You bought some TSLA calls back in 2013 and now you won't shut up giving investing recommendations to your friends. Guaranteed you've posted Instagram stories of you reading the `Value Investor` and your twitter feed is full with Warren Buffet's quotes.",
    "You are the Value Investor. Haven't bought a single tech stock in your life, and yet bragging about the solid 5% overall return you've made since 2010. Low-key jealous of the reddit punks that average 40% without knowing how to read a balance sheet.",
    "You are the CHAD Investor. You've never read a financial statement in your life. You use your Reddit/Twitter followers to pump and dump low market cap stocks, making daily 50% returns. All your portfolio is concentrated on 2/3 tech stocks and you probably have a full-body poster of Elon Musk in your bedroom."

  ]

  function handleSwipe() {
    setCount(prevCount => prevCount + 1);
    setStocks(prevStocks => prevStocks.slice(0, -1));

  }

  useEffect(() => {

    
    if (stocks.length==0) { 
      console.log("finished!");

      const result = investorTypes[Math.floor(Math.random() * investorTypes.length)];

      setResult("Congratulations! " + result);


      // ReactDOM.render( <Fireworks className="fireworks" {...fxProps} />, document.querySelector(".fireworks__container"));

      // onClick={() => window.location.reload()}

      ReactDOM.render( 
        <div className="swipeButtons__test">
        <IconButton>
                <ReplayIcon className="swipeButtons__repeat" fontsize="large" onClick={() => window.location.reload()}/>
            </IconButton></div>
       , document.querySelector(".refresh__btn"));
    }
  },[count, stocks]);

  return (
    <div>
      <div className="tinderCards__cardContainer">
        <div className="fireworks__container"></div>

        <div className="main__container">
          <div className="result" dangerouslySetInnerHTML={{__html: result}}></div>
          <div className="refresh__btn">
          
          </div>
          {stocks.map((stock) => (

            

            <TinderCard
            //   onSwipe={() => setCount(count + 1)}
            //   () => setCount(prevCount => prevCount + 1)a
              onSwipe={() => handleSwipe()}
              className="swipe"
              key={stock.name}
              preventSwipe={["up", "down"]}
            >
              <div className="card">
              {/* <h3 style={{ backgroundColor: "white", textAlign: "center"}}>${stock.name}</h3> */}

                  <img
                    src={stock.url}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%", 
                      backgroundColor: "white"
                    }}/>
              </div>
            </TinderCard>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TinderCards;
