import React, { useContext, useState } from "react";
import "./SwipeButtons.css";

import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";
import StarRateIcon from "@material-ui/icons/StarRate";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import IconButton from "@material-ui/core/IconButton";
import TinderCards from "./TinderCards";
import StocksContext from "./StocksContext";
import LanguageContext from "./LanguageContext";
import ResultsContext from "./ResultsContext";
import ParticleEffectButton from 'react-particle-effect-button'
import { Button } from "@material-ui/core";

// start animation 

function pop (e, type) {
    let amount = 15;
    
    // Quick check if user clicked the button using a keyboard
    if (e.clientX === 0 && e.clientY === 0) {
      const bbox = e.target.getBoundingClientRect();
      const x = bbox.left + bbox.width / 2;
      const y = bbox.top + bbox.height / 2;
      for (let i = 0; i < 15; i++) {
        // We call the function createParticle 30 times
        // We pass the coordinates of the button for x & y values
        createParticle(x, y, type);
      }
    } else {
      for (let i = 0; i < amount; i++) {
        createParticle(e.clientX, e.clientY + window.scrollY, type);
      }
    }
  }
  function createParticle (x, y, type) {
    const particle = document.createElement('particle');
    document.body.appendChild(particle);
    let width = Math.floor(Math.random() * 30 + 8);
    let height = width;
    let destinationX = (Math.random() - 0.5) * 300;
    let destinationY = (Math.random() - 0.5) * 300;
    let rotation = Math.random() * 520;
    let delay = Math.random() * 200;

    particle.style.backgroundImage = 'url(' + type + '.svg)';

    
    particle.style.width = `${width}px`;
    particle.style.height = `${height}px`;
    const animation = particle.animate([
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
        opacity: 1
      },
      {
        transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
        opacity: 0
      }
    ], {
      duration: Math.random() * 1000 + 2000,
      easing: 'cubic-bezier(0, .9, .57, 1)',
      delay: delay
    });
    animation.onfinish = removeParticle;
  }
  function removeParticle (e) {

    e.srcElement.effect.target.remove();
  }

// end animation


function SwipeButtons(props) {

    const {stocks, setStocks } = useContext(StocksContext);
    const { language, setLanguage } = useContext(LanguageContext);
    const { count, setCount } = useContext(ResultsContext);

    const [hidden, setHidden] = useState(false);



    function handleClick(e, type) {
        pop(e, type);
        setCount(count => count + 1);
        setStocks(prevStocks => prevStocks.slice(0, -1));
    }

    
    

    return (
        
        <div className="swipeButtons">
            {/* <IconButton>
                <ReplayIcon className="swipeButtons__repeat" fontsize="large" />
            </IconButton> */}
            

            <IconButton disabled={stocks.length==0 ? true : false}>
                <CloseIcon className="swipeButtons__left" fontsize="large"  onClick={(e) => handleClick(e, 'close')}/>
            </IconButton>



            <IconButton disabled={stocks.length==0 ? true : false}>
                <FlashOnIcon className="swipeButtons__lightning" fontsize="large"  onClick={(e) => handleClick(e, 'thunder')}/>
            </IconButton>

            {/* <IconButton>
                <StarRateIcon className="swipeButtons__star" fontsize="large"  onClick={() => handleClick()}/>
            </IconButton> */}

            <IconButton disabled={stocks.length==0 ? true : false}>
                <FavoriteIcon className="swipeButtons__right" fontsize="large"  onClick={(e) => handleClick(e, 'favorite')}/>
            </IconButton>

            {/* <IconButton>
                <FlashOnIcon className="swipeButtons__lightning" fontsize="large"  onClick={() => handleClick()}/>
            </IconButton> */}
                
        </div>);
}

export default SwipeButtons;