import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Header from "./Header";
import TinderCards from "./TinderCards";
import SwipeButtons from "./SwipeButtons";
import StocksContext from "./StocksContext";
import { useState, useEffect } from "react";
import LanguageContext from "./LanguageContext";
import TestComponent from "./TestComponent";
import ResultsContext from "./ResultsContext";
import Chats from "./Chats";
import ChatScreen from "./ChatScreen";

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function App() {

  const stocksList = [
    {name: 'AAPL', url: 'high-res-images/AAPL.png'},
    {name: 'AMGN', url: 'high-res-images/AMGN.png'},
    {name: 'AXP', url: 'high-res-images/AXP.png'},
    {name: 'BA', url: 'high-res-images/BA.png'},
    {name: 'CAT', url: 'high-res-images/CAT.png'},
    {name: 'CRM', url: 'high-res-images/CRM.png'},
    {name: 'CSCO', url: 'high-res-images/CSCO.png'},
    {name: 'CVX', url: 'high-res-images/CVX.png'},
    {name: 'DIS', url: 'high-res-images/DIS.png'},
    {name: 'DOW', url: 'high-res-images/DOW.png'},
    {name: 'GS', url: 'high-res-images/GS.png'},
    {name: 'HD', url: 'high-res-images/HD.png'},
    {name: 'HON', url: 'high-res-images/HON.png'},
    {name: 'IBM', url: 'high-res-images/IBM.png'},
    {name: 'INTC', url: 'high-res-images/INTC.png'},
    {name: 'JNJ', url: 'high-res-images/JNJ.png'},
    {name: 'JPM', url: 'high-res-images/JPM.png'},
    {name: 'KO', url: 'high-res-images/KO.png'},
    {name: 'MCD', url: 'high-res-images/MCD.png'},
    {name: 'MMM', url: 'high-res-images/MMM.png'},
    {name: 'MRK', url: 'high-res-images/MRK.png'},
    {name: 'MSFT', url: 'high-res-images/MSFT.png'},
    {name: 'NKE', url: 'high-res-images/NKE.png'},
    {name: 'PG', url: 'high-res-images/PG.png'},
    {name: 'TRV', url: 'high-res-images/TRV.png'},
    {name: 'UNH', url: 'high-res-images/UNH.png'},
    {name: 'V', url: 'high-res-images/V.png'},
    {name: 'VZ', url: 'high-res-images/VZ.png'},
    {name: 'WBA', url: 'high-res-images/WBA.png'},
    {name: 'WMT', url: 'high-res-images/WMT.png'},

    {name: 'ABMB', url: 'high-res-images/ABNB.png'},
    {name: 'FB', url: 'high-res-images/FB.png'},
    {name: 'GOOGL', url: 'high-res-images/GOOGL.png'},
    {name: 'BB', url: 'high-res-images/BB.png'},
    {name: 'GME', url: 'high-res-images/GME.png'},
    {name: 'NFLX', url: 'high-res-images/NFLX.png'},
    {name: 'TDOC', url: 'high-res-images/TDOC.png'},
    {name: 'PINS', url: 'high-res-images/PINS.png'},
    {name: 'MOS', url: 'high-res-images/MOS.png'},
    {name: 'TSLA', url: 'high-res-images/TSLA.png'},
    {name: 'BRK', url: 'high-res-images/BRK.png'},

    {name: 'AMZN', url: 'high-res-images/AMZN.png'},
    {name: 'SPOT', url: 'high-res-images/SPOT.png'},
    {name: 'PLTR', url: 'high-res-images/PLTR.png'},
    {name: 'ADYEN', url: 'high-res-images/ADYEN.png'},
    {name: 'TWD', url: 'high-res-images/TWD.png'},
    {name: 'TXN', url: 'high-res-images/TXN.png'},



    
  ];


  const [language, setLanguage] = useState("en");
  const value = { language, setLanguage };

  const [stocks, setStocks] = useState(shuffle(stocksList));
  const value1 = { stocks, setStocks };

  const [count, setCount] = useState(0);
  const value2 = { count, setCount };



  return (
    <LanguageContext.Provider value={value}>
      
          <StocksContext.Provider value={value1}>
            <ResultsContext.Provider value={value2}>
    <div className="App">
      <header className="App-header">
        
      <Router>

        <Switch>
          
          <Route path="/chat/:person">

            <Header backButton="/chat"/>
            <ChatScreen />
          </Route>


          <Route path="/chat">
            <Header backButton="/"/>

            <Chats />
          </Route>

          <Route path="/test">

            <TestComponent/>
          </Route>

          <Route path="/">

              <Header/>

              <TinderCards />
              <SwipeButtons />

          </Route>
        </Switch>

      </Router>

     
      </header>
    </div>
    </ResultsContext.Provider>
    </StocksContext.Provider>

    </LanguageContext.Provider>
  );
}

export default App;
