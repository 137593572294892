import React, { useEffect, useState, useContext } from 'react';
import ResultsContext from './ResultsContext';

function TestComponent(props) {

    const initialCount = 0
    // const [count, setCount] = useState(initialCount);

    const {count, setCount } = useContext(ResultsContext);


    useEffect(() => {
        console.log("count: " + count);
    }, [count]);

    function handleSwipe() {
        console.log("Swiped...Value before incrementing: " + count);
        setCount(count + 1);
        console.log("Value after incrementing: " + count)

    }

    return (
        <div>
            Count: {count}
            <button onClick={() => handleSwipe()}>Reset</button>
            <button onClick={() => handleSwipe()}>Increment</button>
            <button onClick={() => handleSwipe()}>Decrement</button>

        </div>
    );
}

export default TestComponent;