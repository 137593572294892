import React, { useState, useEffect } from 'react';
import PersonIcon from "@material-ui/icons/Person";
import Forum from '@material-ui/icons/Forum'; 
import "./Header.css";
import IconButton from '@material-ui/core/IconButton';
import ReactDOM from 'react-dom';
import SimpleModal from './Modal';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


function Header( {backButton} ) {

    const [show, setShow] = useState(false);
    const history = useHistory();


    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Instructions</h2>
        <p id="simple-modal-description">
            The game consists of 30 stocks. Swipe left to reject and right to like a stock. At the end the game will show you the type of investor you are.
        </p>
        <SimpleModal />
        </div>
    );


    useEffect(() => {

        // ReactDOM.render(
        //     // Try changing to isLoggedIn={true}:
        //     <TransitionsModal />,
        //     document.querySelector(".header")
        //   );

        console.log("show: " +  show);
      },[show]);

    return (
        
        <div className="header">
            <Modal
                open={show}
                onClose={handleClose}
                // aria-labelledby="simple-modal-title"
                // aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            
            <IconButton>

              {backButton ? (
                <ArrowBackIosIcon className="header__icon" fontSize="large" type="button" onClick={() => history.replace(backButton)}/>    
              ) : ( 
                <PersonIcon className="header__icon" fontSize="large" type="button" onClick={() => setShow(!show)}/>    
              )}
            </IconButton>


            <Link to="/">
              <img className="header__logo"
              src="https://swaggystocks.com/static/media/yolos-icon.eb442561.png" 
              alt="Tinder logo"/>
            </Link>


            <Link to="/chat">
              <IconButton>
              <Forum className="header__icon"  fontSize="large"/>
              </IconButton>
            </Link>
        </div>
    );
}

export default Header;